import React from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Logo } from '../../assets';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import styles from './Login.module.css';
import { Header } from '../../components';

const Login = () => {
  const dummyAccountLogin = [
    {
      username: 'quizapp',
      password: 'quizapp',
    },
  ];

  const { register, handleSubmit } = useForm();
  const history = useHistory();

  const onSubmit = (formData) => {
    const userData = dummyAccountLogin.find(
      (user) => user.username === formData.username
    );
    if (userData) {
      if (userData.password === formData.password) {
        history.push({
          pathname: '/quiz',
          state: {
            isLogin: true,
          },
        });
      } else {
        swal({
          title: 'Password incorrect!',
          icon: 'error',
        });
      }
    } else {
      swal({
        title: 'Account not found!',
        icon: 'error',
      });
    }
  };

  return (
    <>
      <Header title="Login" />
      <Container fluid="sm">
        <div className={styles['login-wrapper']}>
          <div className={styles.card}>
            <div className={styles['logo-wrapper']}>
              <img src={Logo} width={180} height={180} alt="logo-quiz" />
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  {...register('username')}
                  type="text"
                  placeholder="Enter username"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  {...register('password')}
                  type="password"
                  placeholder="Enter password"
                  required
                />
              </Form.Group>
              <Button type="submit" variant="success" className="w-100">
                Login
              </Button>
            </Form>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Login;
