import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import styles from './Header.module.css';

const Header = ({ title }) => {
  return (
    <>
      <Navbar bg="light" className={styles.navbar}>
        <Container className="d-flex justify-content-center">
          <Navbar.Brand className="text-muted">
            <b>{title}</b>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
