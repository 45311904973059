import React, { useEffect } from 'react';
import { Container, Card, Button, ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ResultImg } from '../../assets';
import { Header } from '../../components';
import styles from './Result.module.css';

const Result = () => {
  const history = useHistory();

  useEffect(() => {
    const isLogin = history.location.state?.isLogin;
    if (!isLogin) {
      history.push('/');
    }
  });
  const handleClick = (path) => {
    history.push({
      pathname: path,
      state: {
        isLogin: true,
      },
    });
  };
  return (
    <>
      <Header title="Result" />
      <Container>
        <div className={styles['result-wrapper']}>
          <Card className={styles.card} border="light">
            <div className={styles['image-wrapper']}>
              <img src={ResultImg} width={150} height={150} alt="result" />
            </div>
            <ListGroup className="mt-4">
              <ListGroup.Item>
                Jumlah Jawab{' '}
                <b>&nbsp;{history.location.state?.result.questionAnswered}</b>
              </ListGroup.Item>
              <ListGroup.Item>
                Jumlah Benar{' '}
                <b>
                  &nbsp;&nbsp;{history.location.state?.result.correctAnswer}
                </b>
              </ListGroup.Item>
              <ListGroup.Item>
                Jumlah Salah{' '}
                <b>
                  &nbsp;&nbsp;&nbsp;
                  {history.location.state?.result.incorrectAnswer}
                </b>
              </ListGroup.Item>
            </ListGroup>
            <Button
              variant="success"
              className="w-100 mt-3"
              onClick={() => handleClick('/quiz')}
            >
              Ulangi
            </Button>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default Result;
