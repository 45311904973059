import React, { useEffect, useState, useRef } from 'react';
import { Container, Card, Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../hooks';
import { getQuestionsUrl } from '../../utils';
import { decode } from 'html-entities';
import swal from 'sweetalert';
import { Header } from '../../components';
import styles from './Quiz.module.css';

const Quiz = () => {
  const { response, loading, error } = useAxios({ url: getQuestionsUrl });
  const getRandom = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answerOptions, setAnswerOptions] = useState([]);
  const [questionAnswered, setQuestionAnswered] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [incorrectAnswer, setIncorrectAnswer] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [timer, setTimer] = useState(60);

  const history = useHistory();

  const id = useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    const isLogin = history.location.state?.isLogin;
    if (!isLogin) {
      history.push('/');
    }
  });

  useEffect(() => {
    if (showResult) {
      history.push({
        pathname: '/result',
        state: {
          isLogin: true,
          result: {
            questionAnswered,
            correctAnswer,
            incorrectAnswer,
          },
        },
      });
    }
  }, [showResult, questionAnswered, correctAnswer, incorrectAnswer, history]);

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, []);

  useEffect(() => {
    if (timer === 0) {
      swal({
        title: 'Waktu Habis!',
        icon: 'info',
        buttons: 'Lihat Hasil',
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        history.push({
          pathname: '/result',
          state: {
            isLogin: true,
            result: {
              questionAnswered,
              correctAnswer,
              incorrectAnswer,
            },
          },
        });
      });
      clear();
    }
  }, [timer, questionAnswered, correctAnswer, incorrectAnswer, history]);

  useEffect(() => {
    if (response?.results.length) {
      const question = response.results[questionIndex];
      let answers = [...question.incorrect_answers];
      answers.splice(
        getRandom(question.incorrect_answers.length),
        0,
        question.correct_answer
      );
      setAnswerOptions(answers);
    }
  }, [response, questionIndex]);

  const handleClickAnswer = (e) => {
    const question = response.results[questionIndex];
    if (e.target.textContent === decode(question.correct_answer)) {
      setCorrectAnswer(correctAnswer + 1);
    } else {
      setIncorrectAnswer(incorrectAnswer + 1);
    }

    if (questionIndex + 1 < response.results.length) {
      setQuestionIndex(questionIndex + 1);
    } else {
      setShowResult(true);
    }

    setQuestionAnswered(questionAnswered + 1);
  };

  return (
    <>
      <Header title="Quiz App" />
      <Container>
        <div className={styles['quiz-wrapper']}>
          <Card className={styles.card} border="light">
            {response?.results ? (
              <>
                <div className={styles['time-wrapper']}>
                  <h4>
                    Question {questionIndex + 1} / {response?.results.length}
                  </h4>
                  <span>Time left : {timer} </span>
                </div>

                <Card.Header className="mb-3 mt-2">
                  {decode(response?.results[questionIndex].question)}
                </Card.Header>
                {answerOptions.map((option, id) => (
                  <Button
                    variant="success"
                    onClick={(e) => handleClickAnswer(e)}
                    className="mb-2"
                    key={id}
                  >
                    {decode(option)}
                  </Button>
                ))}
              </>
            ) : loading ? (
              <div className={styles.spinner}>
                <Spinner animation="grow" variant="success" />
              </div>
            ) : error ? (
              error
            ) : (
              'Not found'
            )}
          </Card>
        </div>
      </Container>
    </>
  );
};

export default Quiz;
